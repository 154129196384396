import {
  UI_PAGE_WIDGET_FOR_UI_ONBOARDING_FRAGMENT,
} from '@/graphql/_Fragments/UiPageWidget/ForUiOnboarding';

export const UI_ONBOARDING_FULL_FRAGMENT = `
  fragment uiOnboardingFullFragment on UiOnboarding {
    uid
    id
    schemaCode
    code
    enabled
    version
    guest
    autoshow
    page {
      uid
    }
    pageWidgets {
      ...uiPageWidgetForUiOnboardingFragment
    }
  }
  ${UI_PAGE_WIDGET_FOR_UI_ONBOARDING_FRAGMENT}
`;
